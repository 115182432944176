import { useEffect, useState } from "react"
// import style css
import style from "../styles/Branches.module.scss"
// import react icons
import { IoIosArrowDown } from "react-icons/io";
import CallIcon from "../assets/images/icons/CallIcon";
import Location from "../assets/images/icons/Location";
import TimeIcon from "../assets/images/icons/TimeIcon";
import sirr from "../Helpers/Sirr";
import urls from "../ApiValues/urls";
import { useTranslation } from "react-i18next";



export default function Branches() {
	const {t}=useTranslation()
	const [Branches,setBranches]=useState(false) 
    const [contactsData,setContactsData]=useState([])
    const {i18n}=useTranslation()
	const BranchesShowHidden=()=>{ 	setBranches(!Branches)}

	  useEffect(()=>{
          const getContactsData= async()=>{
			try {
				const datas= await sirr.api().get(urls.contacts(i18n.language))
				   setContactsData(datas.data.data)
			} catch (error) {
				console.log(error);
			}
		  }
		  getContactsData()
	  },[i18n.language])
  return (
	<>
	  	<div onClick={BranchesShowHidden} className={style.branchesBtn}> 
		  <div className={style.branches}>{t("branches")}</div>
		  <IoIosArrowDown className={`${style.arrow} ${Branches ? style.arrowRotate : ''}`}/>
		  </div>
		
		<div className={`${style.branchWrapper} ${Branches ? style.branchShow : ""}`}>
          {
			contactsData.map(contact=>(
				<div key={contact.id}>
		{contact.working_hours !== null ? <h5 className={style.AllOpen}><span className={style.iconWrapper}><TimeIcon className={style.icon} />
				</span>{contact.working_hours}</h5> : "" }	
				<h5 className={style.address}><span className={style.iconWrapper}><Location className={style.icon} /></span>{contact.address}</h5>
				<h4 className={style.call} href="">
					<span className={style.iconWrapper}><CallIcon className={style.icon}/></span>
				 {contact.phones.split("/").map((num,i)=>(
					<div className={style.phonesWrapper} key={i}>
					<a href={`tel:${num}`} className={style.phones}>{num}
					</a>
					<span className={style.line}>/</span>
					</div>
				))}
				</h4>
				</div>   
			))
		  }
			</div>
	</>
  )
}


					